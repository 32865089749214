<template>

  <div
    class="transaction-item mt-2"
  >
    <h3 class="mb-1">
      Information personnelle
    </h3>
    <b-media no-body>
      <b-media-aside>
        <b-avatar
          rounded
          size="42"
          variant="light-info"
        >
          <feather-icon
            size="18"
            icon="UserIcon"
          />
        </b-avatar>
      </b-media-aside>
      <b-media-body>
        <h6 class="transaction-title">
          Nom fournisseur
        </h6>
        <small>{{ supplier.username }}</small>
      </b-media-body>
    </b-media>

    <b-media
      no-body
      class="mt-1"
    >
      <b-media-aside>
        <b-avatar
          rounded
          size="42"
          variant="light-success"
          class="bg-lighten-1 "
        >
          <feather-icon
            size="18"
            icon="MailIcon"
          />
        </b-avatar>
      </b-media-aside>
      <b-media-body>
        <h6 class="transaction-title">
          Email
        </h6>
        <small>{{ supplier.email }}</small>
      </b-media-body>
    </b-media>

    <b-media
      no-body
      class="mt-1"
    >
      <b-media-aside>
        <b-avatar
          rounded
          size="42"
          variant="light-primary"
        >
          <feather-icon
            size="18"
            icon="PhoneCallIcon"
          />
        </b-avatar>
      </b-media-aside>
      <b-media-body>
        <h6 class="transaction-title">
          Téléphone
        </h6>
        <small>{{ supplier.phone }}</small>
      </b-media-body>
    </b-media>
    <b-media
      no-body
      class="mt-1"
    >
      <b-media-aside>
        <b-avatar
          rounded
          size="42"
          variant="light-danger"
        >
          <feather-icon
            size="18"
            icon="HomeIcon"
          />
        </b-avatar>
      </b-media-aside>
      <b-media-body>
        <h6 class="transaction-title">
          Adresse
        </h6>
        <small>{{ supplier.address }}</small>
      </b-media-body>
    </b-media>
    <b-media
      no-body
      class="mt-1"
    >
      <b-media-aside>
        <b-avatar
          rounded
          size="42"
          variant="light-warning"
        >
          <feather-icon
            size="18"
            icon="CodeIcon"
          />
        </b-avatar>
      </b-media-aside>
      <b-media-body>
        <h6 class="transaction-title">
          Matricule fiscale
        </h6>
        <small>{{ supplier.tax_identification_number }}</small>
      </b-media-body>
    </b-media>
    <b-media
      no-body
      class="mt-1"
    >
      <b-media-aside>
        <b-avatar
          rounded
          size="42"
          variant="light-info"
        >
          <feather-icon
            size="18"
            icon="DollarSignIcon"
          />
        </b-avatar>
      </b-media-aside>
      <b-media-body>
        <h6 class="transaction-title">
          Prix de livraison
        </h6>
        <small>{{ supplier.price_delivery }}</small>
      </b-media-body>
    </b-media>
    <b-media
      no-body
      class="mt-1"
    >
      <b-media-aside>
        <b-avatar
          rounded
          size="42"
          variant="light-success"
        >
          <feather-icon
            size="18"
            icon="DollarSignIcon"
          />
        </b-avatar>
      </b-media-aside>
      <b-media-body>
        <h6 class="transaction-title">
          Prix de retour
        </h6>
        <small>{{ supplier.price_return }}</small>
      </b-media-body>
    </b-media>
  </div>
</template>

<script>
import {
  BMediaBody, BMedia, BMediaAside, BAvatar,
} from 'bootstrap-vue'
import axios from 'axios'

export default {
  components: {

    BMediaBody,
    BMedia,
    BMediaAside,
    BAvatar,

  },
  // eslint-disable-next-line vue/require-prop-types
  data() {
    return {
      supplier: '',

    }
  },
  created() {
    this.getSupplier()
  },
  methods: {
    async getSupplier() {
      // @ts-ignore
      const { id } = this.$route.params
      await axios.get(`/api/auth/users/detail/${id}`).then(response => {
        this.supplier = response.data
      })
    },
  },

}
</script>
